<template>
  <div v-if="!isHome">
    <HeaderCustom></HeaderCustom>
    <div class="container" :class='$style.myContentsDiv'>
      <div class="row">
        <!-- <div class="col-md-3" v-if="scormTypes.length > 0 && scormLevels.length > 0 && scormCategories.length > 0">
          <div :class="$style.divDdl">
            <select class="form-control" v-model="selectedType" v-html="typesOptions" :class="$style.combobox">
            </select>
          </div>
        </div>
        <div class="col-md-3" v-if="scormTypes.length > 0 && scormLevels.length > 0 && scormCategories.length > 0">
          <div :class="$style.divDdl">
            <select class="form-control" v-model="selectedLevel" v-html="levelsOptions" :class="$style.combobox">
            </select>
          </div>
        </div>
        <div class="col-md-3" v-if="scormTypes.length > 0 && scormLevels.length > 0 && scormCategories.length > 0">
          <div :class="$style.divDdl">
            <select class="form-control" v-model="selectedCategory" v-html="categoriesOptions" :class="$style.combobox">
            </select>
          </div>
        </div>
        <div v-if="scormTypes.length > 0 && scormLevels.length > 0 && scormCategories.length > 0" class="col-md-3">
          <div :class="$style.divDdl">
            <Search :class="$style.divSearch"></Search>
          </div>
        </div> -->
        <div class="col-md-4 offset-md-4">
          <Search></Search>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <button v-if="viewState === 'child' && !readers" class="btn mt-3" style="background-color:#6f2b8f; color:white" @click="$router.push({name: 'collections'})"><i class="fas fa-arrow-circle-left"></i> Back</button>
        </div>
        <div class="col-md-4">
          <h1 class="title menuTitle" style="font-weight: normal;" :class='$style.menuTitle'>{{title}}</h1>
        </div>
        <div class="col-md-4">
        </div>
      </div>
      <div v-if="!ready">
          <InnerLoading></InnerLoading>
      </div>
      <div v-else-if="this.data.length > 0 || this.packageData.length > 0" :class="$style.columns">
        <hr>
        <div v-if="viewState === 'parent'" class="row">
          <div class="col-lg-3 col-md-4 col-6 col-sm-6 col-xs-12 mt-2" :class="$style.column" v-for="(parent,x) in data" :key="x">
            <Card  :contentType='contentType' :id="parent.id" :title="parent.name" :imgpath='parent.image ? parent.image : "https://storage.googleapis.com/ueslmscom/uploads/collection/1560865423.jpg"'></Card>
          </div>
          <div class="col-lg-3 col-md-4 col-6 col-sm-6 col-xs-12 mt-2" :class="$style.column" v-for="(parent) in packageData" :key="parent.package_name">            
            <Card  contentType='collectionsPackage' :id="parent.package_id" :title="parent.package_name" :imgpath='parent.package_image ? parent.package_image : "https://storage.googleapis.com/ueslmscom/uploads/collection/1560865423.jpg"'></Card>
          </div>
        </div>
        <div v-else-if="viewState === 'child' && !readers" class="row">
          <div class="col-lg-2 col-md-3 col-6 col-sm-6 col-xs-12 mt-2" :class="$style.column" v-for="(child,i) in data" :key="i">
            <Card  :contentType='contentType' :collectionId='parseInt($route.params.id, 10)' :id="child.id" :title="child.name" :imgpath='child.image'></Card>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-lg-2 col-md-3 col-6 col-sm-6 col-xs-12 mt-2" :class="$style.column" v-for="(child,i) in packageData"
            :key="i">
            <Card contentType='lessons' :collectionId='parseInt($route.params.id, 10)' :packageType='child.package_type' :id='child.package_id' :title='child.package_name' 
            :imgpath='child.package_image ? child.package_image : "https://storage.googleapis.com/ueslmscom/uploads/collection/1560865423.jpg"'
              :score='child.package_avg_score'></Card>
        </div>
      </div>
      </div>
       <div v-else>
        <p :class="$style.noContent">There is no content here.</p>
      </div> 
    </div>
  </div>
  <div v-else class="row" style="background:white; border-radius: 10px; box-shadow: 0.5px 0.866px 4px 0px rgba(2, 8, 2, 0.2);">
    <div class="col-md-12 col-sm-12 mt-1">
      <div :class="$style.divCollectionsHome">
        <div class="row">
          <div class="col-md-12">
            <router-link :to="{ name: 'collections'}" :class='$style.customNavItem'>
              <span class="title text-left" :class='$style.title'>RESOURCES</span>
            </router-link>
          </div>
        </div>
        <div v-if="!ready">
          <InnerLoading :class="$style.divLoadingHome"></InnerLoading>
        </div>
        <div v-else-if="this.data.length > 0" :class="$style.columns">
          <div v-if="viewState === 'parent'" class="row">
            <div class="col-md-3 mt-1" 
            v-for="(child,i) in data.filter(x => x.parent === 0).slice(0, 4)" :key="i">
              <Card2 :class="$style.columnHome" :contentType='contentType' :id='child.id' :title='child.name' :imgpath='child.image'></Card2>
            </div>
                <div class="col-md-3 mt-1"  v-for="(parent) in packageData.slice(0, 4 - data.length)" :key="parent.package_name">            
                <Card2 :class="$style.columnHome" contentType='collectionsPackage' :id="parent.package_id" :title="parent.package_name" :imgpath='parent.package_image ? parent.package_image : "https://storage.googleapis.com/ueslmscom/uploads/collection/1560865423.jpg"'></Card2>
              </div>
          </div>
        </div>
        <div v-else>
          <p :class="$style.noContentHome">There is no content here.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import _ from 'lodash';
  import HeaderCustom from '@/components/HeaderCustom';
  import InnerLoading from '@/components/InnerLoading';

  import Card from '@/components/Card3';
  import Card2 from '@/components/Card2';


  import Search from '@/components/Search';

  export default {
    name: 'collections',
    data() {
      return {
        data: [],
        packageData: [],
        title: '',
        contentType: '',
        viewState: '',
        ready: false,
        collectionId: 0,
        scormTypes: [],
        scormLevels: [],
        scormCategories: [],
        selectedType: 'all',
        selectedLevel: 0,
        selectedCategory: 0,
        searchText: '',
        typesOptions: '',
        levelsOptions: '',
        categoriesOptions: '',
        readers: false,
      };
    },
    watch: {
      $route() {
        this.setPage();
      },
      selectedType() {
        this.setDataByFilter();
      },
      selectedLevel() {
        this.setDataByFilter();
      },
      selectedCategory() {
        this.setDataByFilter();
      },
    },
    components: {
      HeaderCustom,
      Card,
      Search,
      InnerLoading,
      Card2,
    },
    props: ['isHome'],
    computed: {
      ...mapState(['collections', 'scorms', 'freeContents']),
    },
    async beforeMount() {
      this.title = 'RESOURCES';
      if (!this.isHome) {
        this.fillDropdowns();
      }
      if (this.freeContents.length === 0) await this.getPackages();
      if (this.collections.length === 0) await this.getCollections();
      if ((this.collections.length === 0) && await this.getCollections()) {
        if (this.scorms.length === 0) await this.getScorms();
      }
      this.viewState = 'parent';
      //! Eğer Collectionlardaki kırılım sayısı 2 ise direk scorms sayfasına gitmeli
      //! 3 ise colections sayfasına gitmeli
      this.contentType = 'collections';
      this.setPage();
      this.ready = true;
    },
    methods: {
      ...mapActions(['getPackages', 'getCollections', 'getScorms']),
      search(text) {
        if (!text) {
          this.searchText = '';
        } else {
          this.searchText = text.toLocaleLowerCase('tr-TR');
        }
        this.setDataByFilter();
      },
      fillDropdowns() {
        this.scormTypes = _.uniqBy(this.scorms, 'scorm_type')
                                  .map(x => x.scorm_type);
        this.typesOptions = '';
        this.typesOptions += '<option value="all">All Types</option>';
        _.orderBy(this.scormTypes).forEach((type) => {
          const opt = `<option value='${type}'>${type.toUpperCase()}</option>`;
          this.typesOptions += opt;
        });
        this.scormLevels = _.uniqBy(this.scorms, 'scorm_level.name')
                                  .filter(x => x.scorm_level && x.scorm_level.id !== null)
                                  .map(x => x.scorm_level);
        this.levelsOptions = '';
        this.levelsOptions += '<option value="0">All Levels</option>';
        _.orderBy(this.scormLevels, 'name').forEach((lvl) => {
          const opt = `<option value='${lvl.id}'>${lvl.name}</option>`;
          this.levelsOptions += opt;
        });
        this.scormCategories = _.uniqBy(this.scorms, 'scorm_category.name')
                                  .filter(x => x.scorm_category && x.scorm_category.id !== null)
                                  .map(x => x.scorm_category);
        this.categoriesOptions = '';
        this.categoriesOptions += '<option value="0">All Categories</option>';
        _.orderBy(this.scormCategories, 'name').forEach((cat) => {
          const opt = `<option value='${cat.id}'>${cat.name}</option>`;
          this.categoriesOptions += opt;
        });
      },
      setDataByFilter() {
        /*eslint-disable*/
        if (!this.searchText) {
          this.setPage();
        } else {
          this.setPage();
          this.data = this.data.filter(x => x.name.toLocaleLowerCase('tr-TR').includes(this.searchText));
          this.packageData = this.packageData.filter(x => x.package_name.toLocaleLowerCase('tr-TR').includes(this.searchText));
        }
        // const filteredScorms = this.scorms
        //                             .filter(x => Number(this.selectedLevel) !== 0 ? (x.scorm_level !== null &&
        //                                               x.scorm_level.id === Number(this.selectedLevel)) :
        //                                               true)
        //                             .filter(x => Number(this.selectedCategory) !== 0 ? (x.scorm_category !== null &&
        //                                               x.scorm_category.id === Number(this.selectedCategory)) :
        //                                               true)
        //                             .filter(x => this.selectedType !== 'all' ?
        //                                               x.scorm_type === this.selectedType :
        //                                               true);
        // const filteredCollections = _.uniqBy(filteredScorms, 'collection_id')
        //                             .map(x => x.collection_id);
        // const subsData = [];
        // console.log(filteredCollections);
        // filteredCollections.forEach((item) => {
        //   const element = this.collections.find(x => x.id === Number(item));
        //   if (element.name.toLocaleLowerCase('tr-TR').includes(this.searchText)) {
        //     subsData.push(element);
        //     const parent = this.collections.find(x => x.id == element.parent);
        //     if (!subsData.some(x => x.id === parent.id)) {
        //       subsData.push(parent);
        //     }
        //   }
        // });
        // this.data = subsData;
        // this.packageData = this.freeContents.filter(x => x.package_name.toLocaleLowerCase('tr-TR').includes(this.searchText));
        // for (const item of this.packageData) {
        //   if (item.package_parent_id) {
        //     const parent =  this.freeContents.find(x => x.package_id === item.package_parent_id);
        //     if (!this.packageData.find(y => y.package_id === parent.package_id)) {
        //       this.packageData.push(parent);
        //     }
        //   }
        // }
      },
      setPage() {
        const collectionId = parseInt(this.$route.params.id, 10);
        if (collectionId) {
          this.collectionId = collectionId;
          this.viewState = 'child';
          const { searchList } = this.$route.params;
          this.contentType = 'scorms';
          if (searchList === 'packages') {
            this.packageData = this.freeContents.data.filter(x => x.package_parent_id === collectionId);
            this.packageData = _.orderBy(this.packageData, ['name']);
            this.data = [];
            this.readers = true;
          } else if (searchList === 'collections') {
            this.data = this.collections.data.filter(x => x.parent === collectionId);
            this.data = _.orderBy(this.data, ['name']);
            this.packageData = [];
            this.readers = false;
          }
        } else {
          this.viewState = 'parent';
          this.contentType = 'collections';
          this.data = this.collections.data.filter(x => x.parent === 0 && x.id !== 95);
          this.data = _.orderBy(this.data, ['name']);
          this.packageData = this.freeContents.filter(x => !x.package_parent_id);
          this.readers = false;
        }
      },
    },
  };

</script>

<style src='./style.less' lang='less' module/>
