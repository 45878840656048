module.exports = {
  global_key: {
    tokenName: 'access_token',
  },
  colors: {
    primaryColor: '#5C3B82',
    secondaryColor: '#EE1E47',
    backgroundColor: 'whitesmoke',
  },
  apiKey: 'ddN1SH6joa94pLNPliKGWZSyw5250CK0lpI17R52eADSb68c',
};
